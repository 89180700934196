import type { CustomService } from "~/server/util/sitemap/getDataForDepartments";
import type { GroupOrService } from "~/types/GroupOrService";
import type { Service } from "~/types/strapi/api/service/content-types/service/service";

export type ServicesAndGroups = {
  [key: string]: GroupOrService;
};

export const getServicesAndGroupsFromDepartmentServices = (
  departmentServices: CustomService[] | Service[],
): GroupOrService[] => {
  const servicesAndGroups = departmentServices.reduce<ServicesAndGroups>((acc, serviceInfo) => {
    const service = serviceInfo.attributes;
    const hasGroup = service.group?.data !== null && service.group?.data !== undefined;
    const data = hasGroup ? service.group?.data?.attributes : service;
    const order = data?.order ?? departmentServices.length;

    if (data === undefined || data.uuid === undefined) {
      return acc;
    }

    const serviceData: GroupOrService = {
      description: data.description,
      id: data.uuid,
      name: data.name,
      order: `${hasGroup ? "a" : "b"}${order.toString().padStart(5, "0")}`,
      services: hasGroup ? [] : null,
      uuid: data.uuid,
    };

    if (!hasGroup) {
      serviceData.serviceId = service.serviceId;
    }

    if (data?.uuid && !acc[data.uuid]) {
      acc[data.uuid] = serviceData;
    }

    if (hasGroup && data?.uuid && !acc[data.uuid].services?.[service.serviceId]) {
      acc[data.uuid].services?.push(service.serviceId);
    }

    return acc;
  }, {});

  return Object.values(servicesAndGroups).sort(sortServicesByOrderName);
};

function sortServicesByOrderName(a: GroupOrService, b: GroupOrService) {
  if (a.order > b.order) return 1;
  if (a.order < b.order) return -1;

  // fallback to name
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;

  return 0;
}
